import axios from 'axios';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import configData from '../../../config';

const SlackCallback = () => {
    const history = useHistory();

    useEffect(() => {
        const handleAuthResponse = async () => {
            try {
                const params = new URLSearchParams(window.location.search);
                const code = params.get('code');

                if (!code) {
                    console.error('Authorization code not found.');
                    return;
                }

                // Retrieve local user ID from local storage (assuming it's stored there)
                const user = JSON.parse(localStorage.getItem('user'));
                const userId = user ? user.id : null;

                console.log('Local user:', user);
                console.log('Local user ID:', userId);

                if (!userId) {
                    console.error('Local user ID not found.');
                    return;
                }

                // Send the Slack authorization code and local user ID to the backend
                const response = await axios.get(`${configData.API_SERVER}users/slack/callback`, {
                    params: { code, user_id: userId } // Correct param key: 'user_id'
                });

                console.log('Response from /users/slack/callback:', response.data);

                if (response.data.message === 'Slack connected successfully') {
                    // Redirect the user back to the integrations page
                    history.push('/dashboard/integration');
                } else {
                    console.error('Slack connection failed:', response.data.error);
                }
            } catch (error) {
                console.error('Error handling Slack auth response', error);
            }
        };

        handleAuthResponse();
    }, [history]);

    return <div>Connecting to Slack...</div>;
};

export default SlackCallback;
